export function padZero(num, targetLength = 2) {
  let str = num + ''
  while (str.length < targetLength) {
    str = '0' + str
  }
  return str
}

function formatTimeStamp(timeStamp) {
  // compatible when the timestamp is seconds
  if (timeStamp < 10 ** 12) {
    return timeStamp * 1000
  }
  return +timeStamp
}

export function getYearMonthDay(timeStamp) {
  const date = new Date(formatTimeStamp(timeStamp))
  return `${date.getFullYear()}.${padZero(date.getMonth() + 1)}.${padZero(
    date.getDate()
  )}`
}
