<template>
  <div>
    <van-form @submit="onSubmit">
      <!--账号-->
      <van-field
        v-model="account"
        name="account"
        label="账号"
        placeholder="账号"
        @input="accountChange"
        :rules="[
          { required: true, message: '请输入账号', validator: asyncValidator },
        ]"
      >
        <van-button
          v-show="accountList.length > 0"
          slot="button"
          size="small"
          type="primary"
          @click="accountShowPicker = true"
          native-type="button"
          >历史账号</van-button
        >
      </van-field>
      <van-popup v-model="accountShowPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="accountList"
          @confirm="accountConfirm"
          @cancel="accountShowPicker = false"
        />
      </van-popup>

      <!--系统-->
      <van-field
        readonly
        clickable
        name="os"
        :value="osText"
        label="系统"
        placeholder="选择系统"
        @click="osShowPicker = true"
        :rules="[{ required: true }]"
      />
      <van-popup v-model="osShowPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="osList"
          @confirm="osConfirm"
          @cancel="osShowPicker = false"
        />
      </van-popup>

      <!--游戏-->
      <van-field
        readonly
        clickable
        name="game"
        :value="gameText"
        label="游戏"
        placeholder="选择游戏(请先选择系统)"
        @click="gameShowPicker = true"
        :rules="[{ required: true }]"
      />
      <van-popup v-model="gameShowPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="gameList"
          @confirm="gameConfirm"
          @cancel="gameShowPicker = false"
        />
      </van-popup>

      <van-notice-bar
        v-if="sale_info"
        wrapable
        :scrollable="false"
        :text="sale_info"
      />

      <!--区服-->
      <van-field
        readonly
        clickable
        name="server"
        :value="serverText"
        label="区服"
        placeholder="选择区服(请先选择游戏)"
        @click="serverShowPicker = true"
        :rules="[{ required: true }]"
      />
      <van-popup v-model="serverShowPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="serverList"
          @confirm="serverConfirm"
          @cancel="serverShowPicker = false"
        />
      </van-popup>

      <!--角色-->
      <van-field
        readonly
        clickable
        name="role"
        :value="roleText"
        label="角色名"
        placeholder="选择角色(请先选择区服)"
        @click="roleShowPicker = true"
        :rules="[{ required: true }]"
      />
      <van-popup v-model="roleShowPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="roleList"
          @confirm="roleConfirm"
          @cancel="roleShowPicker = false"
        />
      </van-popup>

      <!--金额-->
      <van-field
        readonly
        clickable
        name="money"
        :value="moneyText"
        label="充值金额"
        placeholder="选择金额"
        @click="moneyShowPicker = true"
        :rules="[{ required: true }]"
      />
      <van-popup v-model="moneyShowPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="moneyList"
          @confirm="moneyConfirm"
          @cancel="moneyShowPicker = false"
        />
      </van-popup>

      <div class="van-cell" style="color: red;" v-show="discountTipsShow == true">
        <div class="van-cell__title">
          <span>{{ discountTips }}</span>
        </div>
      </div>

      <!--充值道具-->
      <van-field
        v-model="product_name"
        name="product_name"
        label="充值道具"
        placeholder="充值道具"
        readonly
      />

      <van-notice-bar
        v-show="discount_show == true"
        wrapable
        :scrollable="false"
        :text="discount_info"
      />

      <!-- 优惠券单元格 -->
      <!-- <van-coupon-cell
        title="代金券"
        :coupons="coupons"
        :chosen-coupon="chosenCoupon"
        @click="showCouponList = true"
      /> -->


      <!--支付方式-->
      <van-cell title="支付方式"> </van-cell>

      <van-radio-group v-model="payTypeId">
        <van-cell-group>
          <van-cell clickable @click="payTypeId = '2'">
            <van-image
              class="pay_icon"
              :src="require('@/assets/credit/pay_icon/wechat.png')"
            />
            <div class="pay_desc">（支持人民币）</div>
            <template #right-icon>
              <van-radio name="2" />
            </template>
          </van-cell>
          <van-cell clickable @click="payTypeId = '3'">
            <van-image
              class="pay_icon"
              :src="require('@/assets/credit/pay_icon/paypal.png')"
            />
            <div class="pay_desc">（支持外币，账单显示金额为美元 USD）</div>
            <template #right-icon>
              <van-radio name="3" />
            </template>
          </van-cell>
          <van-cell clickable @click="payTypeId = '1'">
            <van-image
              class="pay_icon"
              :src="require('@/assets/credit/pay_icon/alipay.png')"
            />
            <div class="pay_desc">（支持人民币）</div>
            <template #right-icon>
              <van-radio name="1" />
            </template>
          </van-cell>
          <!-- <van-cell clickable @click="payTypeId = '7'">
                        <van-image class="pay_icon" :src="require('@/assets/credit/pay_icon/alipay.png')" />
                        <div class="pay_desc">（支持外币，账单显示金额为美元 USD）</div>
                        <template #right-icon>
                            <van-radio name='7' />
                        </template>
                    </van-cell> -->
        </van-cell-group>
      </van-radio-group>

      <div style="margin: 16px">
        <van-button style="width: 45%;float:left" type="primary">立即充值</van-button>
        <van-button
          style="width: 45%; float: right"
          type="info"
          @click="reset"
          native-type="button"
          >重置</van-button
        >
      </div>
    </van-form>
    <van-cell
      title="温馨提醒：充值时请确保角色在本服（非跨服地图）！"
      style="color: red"
    />
    <!-- 优惠券列表 -->
      <van-popup
        v-model="showCouponList"
        round
        position="bottom"
        style="height: 90%; padding-top: 4px"
      >
        <van-coupon-list
          :coupons="coupons"
          :chosen-coupon="chosenCoupon"
          :disabled-coupons="disabledCoupons"
          :show-exchange-bar="false"
          @change="onCouponChange"
        />
      </van-popup>
  </div>
</template>

<script>
import {
  accountInfo,
  gameList,
  roleList,
  productsList,
  serverList,
  // createOrder,
  commonCreateOrder,
  payMix,
  payWxRedirect
} from "@/api/secret";
// import { Toast, CouponCell, CouponList } from "vant";
import { Toast } from "vant";
import { Base64 } from "js-base64";
import { couponList } from "@/api/user";
import { getYearMonthDay } from '@/utils/format';
import { getDiscountInfo } from "@/api/common/discount";

const PAY_TYPE_WX = 2
const PAY_TYPE_ALIPAY = 1
const PAY_TYPE_PAYPAL = 3

export default {
  // components: {
  //   vanCouponCell: CouponCell,
  //   vanCouponList: CouponList,
  // },
  data() {
    return {
      first_name:'',
      last_name:'',
      email:'',
      account: "", //账号
      accountList: [],
      accountShowPicker: false,
      user_id: 0, //用户id
      user_email: null, //用户id
      game_id: "", //游戏id
      is_sale: 0, //是否打折 0不打折
      sale_info: "", //销售信息
      discount_info: "恭喜你，本次充值额外赠送10%！", //折扣信息
      discount_show: false, //显示打折信息
      discountTips: "", //  获取折扣信息[此字段：正式使用时，初始化值为空，通过接口获取该值（包括折扣信息）]
      discountTipsShow: false, //  获取折扣信息[此字段：正式使用时，初始化值为空，通过接口获取该值（包括折扣信息）]
      product_id: 0, //后台自增id 非product_id
      product_name: "", //物品名
      payState: false, //防止重复点击
      osId: "",
      osText: "", //选中内容
      osList: ["苹果", "安卓"], //系统列表
      osShowPicker: false,
      gameText: "", //选中游戏
      gameList: [], //游戏列表
      gameShowPicker: false,
      serverText: "", //选中区服
      serverList: [], //区服列表
      serverShowPicker: false,
      roleText: "", //选中角色
      roleList: [], //角色列表
      roleId: 0,
      roleShowPicker: false,
      moneyText: "", //选中金额
      moneyList: [], //金额列表
      moneyShowPicker: false,
      payTypeId: "2",
      chosenCoupon: -1,
      showCouponList: false,
      coupons: [],
      couponList: [],
      disabledCoupons: [],
      couponId: undefined,
    };
  },
  created() {
    if (localStorage.role_info) {
      const role_info =
        typeof localStorage.role_info === "string"
          ? JSON.parse(localStorage.role_info)
          : localStorage.role_info;
      this.accountList = Object.keys(role_info);
      console.log(this.accountList);
    }
  },
  methods: {
    //  获取折扣信息接口
    getDiscountInfo(params) {
      getDiscountInfo(params).then((response) => {
        console.log(response)
        console.log(response.content)
        if(response.content) {
          this.discountTipsShow = true;
          this.discountTips = response.content
        } else{
          this.discountTipsShow = false;
        }
        // this.discountTips = response.content;
      }).catch(() => {
        //  折扣信息接口不存在
        this.discountTipsShow = false;
      });
    },
    onSubmit() {
      console.log("onSubmit");
      const that = this;
      if (that.payState) {
        return false;
      }
      that.payState = true;
      Toast.loading({
        message: "登录中，请稍等!",
        forbidClick: true,
      });
      console.log("payTypeId", parseInt(this.payTypeId));
      this.saveUserRole()
      // console.log(localStorage.role_info);
      this.orderStore()

    },
    reset: function () {
      this.changeReset("all");
    },
    accountConfirm(value) {
      this.accountShowPicker = false;
      if (localStorage.role_info) {
        const role_info =
          typeof localStorage.role_info === "string"
            ? JSON.parse(localStorage.role_info)
            : localStorage.role_info;
        if (role_info[value]) {
          const account_item = role_info[value];
          console.log("选中账号", JSON.stringify(account_item));

          //  ----获取折扣信息接口Start----
          this.getDiscountInfo({'game_slug':account_item.game_id});
          //  ----获取折扣信息接口End----

          // 填充数据
          this.reset();
          this.account = account_item.account;
          this.user_id = account_item.user_id;
          this.user_email = account_item.user_email;
          this.game_id = account_item.game_id;
          this.gameText = account_item.gameText;
          this.osId = account_item.osId;
          this.osText = account_item.osText;
          this.serverText = account_item.serverText;
          this.roleText = account_item.roleText;
          this.roleId = account_item.roleId;
          this.sale_info = account_item.sale_info;
          this.gameList = account_item.gameList;
          this.serverList = account_item.serverList;
          this.roleList = account_item.roleList;

          this.roleConfirm({ id: this.roleId, text: this.roleText });
        }
        // 获取优惠券
        // this.getCouponList();
      }
    },
    osConfirm(value) {
      this.osText = value;
      this.osShowPicker = false;
      this.osId = value == "安卓" ? 1 : 2;
      this.changeReset("os");
      this.getGameList({ os: this.osId });
    },
    gameConfirm(value) {
      if (this.account == "" || this.user_id == 0) {
        Toast.fail("请先输入账号");
        return false;
      }
      this.game_id = value.slug;
      this.gameText = value.text;
      this.gameShowPicker = false;
      this.sale_info = value.sale_info;
      this.changeReset("game");

      //  ----获取折扣信息接口Start----
      this.getDiscountInfo({'game_slug':this.game_id});
      //  ----获取折扣信息接口End----

      serverList({ game_id: this.game_id, os: this.osId })
        .then((response) => {
          if (response.length == 0) {
            this.$toast({
              message: "该游戏暂无区服",
              duration: 500,
            });
          } else {
            this.$toast({
              message: "获取区服列表成功",
              duration: 500,
            });
            this.serverList = response;
            this.roleText = this.moneyText = "";
            this.roleId = 0;
            this.roleList = this.moneyList = [];
          }
        })
        .catch(() => {});
    },
    serverConfirm(value) {
      this.serverText = value.text;
      this.serverShowPicker = false;
      this.roleList = [];
      this.changeReset("server");
      roleList({
        game_id: this.game_id,
        user_id: this.user_id,
        server: this.serverText,
        os: this.osId,
      })
        .then((response) => {
          if (response.length == 0) {
            this.$toast({
              message: "该区服,您尚未创建角色",
              duration: 1000,
            });
          } else {
            this.$toast({
              message: "获取角色列表成功",
              duration: 500,
            });
            this.roleList = response;
          }
        })
        .catch(() => {});
    },
    roleConfirm(value) {
      let os = this.osText == "安卓" ? 1 : 2;
      this.roleText = value.text;
      this.roleId = value.id;
      this.roleShowPicker = false;
      this.changeReset("role");
      productsList({ game_id: this.game_id, os: os })
        .then((response) => {
          this.$toast({
            message: "获取充值金额列表成功",
            duration: 500,
          });
          this.moneyList = response;
        })
        .catch(() => {});
    },
    moneyConfirm(value) {
      this.moneyText = value.text;
      this.product_id = value.id;
      this.product_name = value.product_name;
      this.moneyShowPicker = false;
      this.discount_show = false;
      this.coupons = [];
      this.disabledCoupons = [];
      this.couponList.forEach((el) => {
        let coupon = {
          coupon_id: el.id,
          available: 1,
          reason: el.desc,
          value: el.discount * 100,
          name: el.name,
          // startAt: parseInt(new Date(el.start_at).getTime()),
          // endAt:  parseInt(new Date(el.expiry_at).getTime()),
          valueDesc: parseInt(el.discount),
          unitDesc: "元",
          description: el.desc,
          customValidPeriod: '有效期至 ' + getYearMonthDay(new Date(el.expiry_at).getTime())
        };
        if (parseInt(el.over) === 0) {
          coupon.condition = '直减'
        } else {
          coupon.condition = '满 ' + parseInt(el.over) + ' 元可用'
        }
        // 如果充值金额大等于代金券满金额
        if (
          parseFloat(el.over) === 0 ||
          parseFloat(this.moneyText) >= parseFloat(el.over)
        ) {
          this.coupons.push(coupon);
        } else {
          this.disabledCoupons.push(coupon);
        }
      });
    },
    asyncValidator(val) {
      accountInfo({ account: val })
        .then((response) => {
          this.user_id = response.id;
          this.user_email = response.email;
          // 获取优惠券
          // this.getCouponList();
        })
        .catch(() => {});
    },
    accountChange() {
      this.changeReset("account");
    },
    getGameList(data = undefined) {
      gameList(data)
        .then((response) => {
          this.$toast({
            message: "获取游戏列表成功",
            duration: 500,
          });
          this.gameList = response;
        })
        .catch(() => {});
    },
    changeReset(changeSetup) {
      switch (changeSetup) {
        case "all":
          this.user_id = 0;
          this.account = "";
        /* falls through */
        case "account":
          this.osId = "";
          this.osText = "";
          console.log("changeSetup account");
        /* falls through */
        case "os":
          this.game_id = 0;
          this.gameText = "";
          this.gameList = [];
          this.sale_info = "";
        /* falls through */
        case "game":
          this.serverText = "";
          this.serverList = [];
        /* falls through */
        case "server":
          this.roleText = "";
          this.roleId = 0;
          this.roleList = [];
        /* falls through */
        case "role":
          this.moneyText = this.product_name = "";
          this.moneyList = [];
          this.discount_show = false;
          this.product_id = 0;
          this.payTypeId = "2";
          break;
      }
    },
    onCouponChange(index) {
      this.showCouponList = false
      this.chosenCoupon = index
      if (index >= 0) {
        this.couponId = this.coupons[index]["coupon_id"]
      } else {
        this.couponId = undefined
      }
    },
    getCouponList() {
      couponList({ account: this.account, user_id: this.user_id }).then(
        (response) => {
          this.couponList = response;
          this.coupons = [];
          this.disabledCoupons = [];
          this.chosenCoupon = -1;
        }
      );
    },
    resetMoney() {
      this.moneyText = this.product_name = this.product_id = undefined
      // this.getCouponList()
    },
    // 创建订单
    orderStore() {
      const payTypeId = parseInt(this.payTypeId)
      commonCreateOrder({
        user_id: this.user_id,
        game_id: this.game_id,
        server: this.serverText,
        role_name: this.roleText,
        product_id: this.product_id,
        os: this.osText,
        pay_type_id: payTypeId,
        coupon_id: this.couponId,
      }).then((response) => {
        this.resetMoney()
        this.payState = false
        // 如果代金券直接减完支付完成
        if (response.pay_success && response.pay_success === true) {
          this.$toast.success('支付成功')
        } else {
          this.toPay(response.number)
        }
      }).catch(() => {
        this.payState = false
      })
    },
    // 发起支付
    toPay(orderNum) {
      Toast.loading({
        message: "发起支付中，请稍等!",
        forbidClick: true,
        duration: 0,
      });
      const payTypeId = parseInt(this.payTypeId)
      if (payTypeId === PAY_TYPE_WX) {
        this.payWx(orderNum)
      } else if (payTypeId === PAY_TYPE_ALIPAY) {
        this.payAlipay(orderNum)
      } else if (payTypeId === PAY_TYPE_PAYPAL) {
        this.payPaypal(orderNum)
      }
    },
    // 微信
    payWx(orderNum) {
      payWxRedirect(orderNum).then((response) => {
        window.location.href = response.url
      }).catch(() => {
        Toast.clear(true)
        this.$toast.fail('发起支付失败')
      })
    },
    // PayPal
    payPaypal(orderNum) {
      payMix({
        number: orderNum,
        user_id: this.user_id,
        pay_type: parseInt(this.payTypeId),
      }).then((res) => {
        console.log(res)
        window.location.href = res.url
        Toast.clear(true)
        Toast.loading({
          message: "跳转支付页面...",
          forbidClick: true,
          duration: 3000,
        });
      }).catch(() => {
        this.$toast.fail('发起支付失败')
      });
    },
    // 支付宝
    payAlipay(orderNum) {
      // 跳转中间页
      Toast.clear(true);
      console.log("跳转支付中间页");
      const key = Base64.encode(
        JSON.stringify({
          user_id: this.user_id,
          number: orderNum,
          pay_type: parseInt(this.payTypeId),
        })
      )
      this.$router.push({
        path: "/credit/mid",
        query: {
          key: key,
        },
      })
    },
    // 保存用户信息
    saveUserRole() {
      const account_item = {
        account: this.account,
        user_id: this.user_id,
        osId: this.osId,
        osText: this.osText,
        game_id: this.game_id,
        gameText: this.gameText,
        gameList: this.gameList,
        serverText: this.serverText,
        serverList: this.serverList,
        roleText: this.roleText,
        roleId: this.roleId,
        roleList: this.roleList,
        sale_info: this.sale_info,
      };
      if (this.accountList.indexOf(this.account) === -1) {
        this.accountList.push(this.account)
      }
      console.log("accountList", JSON.stringify(this.accountList));
      if (localStorage.role_info) {
        let role_info =
          typeof localStorage.role_info === "string"
            ? JSON.parse(localStorage.role_info)
            : localStorage.role_info
        role_info[this.account] = account_item
        localStorage.role_info = JSON.stringify(role_info)
      } else {
        localStorage.role_info = JSON.stringify({
          [this.account]: account_item,
        })
      }
    }
  },
};
</script>

<style>
.pay_icon {
  width: 35px;
  height: 44px;
  float: left;
}
.pay_desc {
  float: left;
  display: block;
  line-height: 44px;
  margin-left: 5px;
}
.title_coupon {
  margin-right: 5px;
}
/* .van-coupon-list__list {
  height: 100% !important;
} */
</style>
