//import manager from '@/utils/manager'
import api from '@/utils/api'

export function accountInfo(params) {
    return api.get('wx/v1/secret/account', { params:params })
}

export function gameList(params) {
    return api.get('wx/v1/secret/game', { params:params })
}

export function serverList(params) {
    return api.get('wx/v1/secret/server', { params:params })
}

export function roleList(params) {
    return api.get('wx/v1/secret/role', { params:params })
}
export function getServerList(params) {
    return api.get('wx/v1/secret/get_server', { params:params })
}

export function getRoleList(params) {
    return api.get('wx/v1/secret/get_role', { params:params })
}
export function getGameId(params) {
    return api.get('wx/v1/secret/get_game', { params:params })
}

export function productsList(params) {
    return api.get('wx/v1/secret/products', { params:params })
}

export function createOrder(params) {
    return api.post('wx/v1/jzac', params)
}

export function pay(params) {
    return api.post('wx/v1/wolf/pay', params)
}

export function commonCreateOrder(params) {
    return api.post('wx/v1/wolf/common/jzac', params)
}

export function nuHuoCreateOrder(params) {
    return api.post('wx/v1/nuhuo/common/jzac', params)
}

//支付包支付或paypel支付
export function payMix(params) {
    // return api.post('wx/v1/pay/mix', params)
    return api.post('wx/v1/official_account/pay', params)
}

//公众号微信支付
export function payWxRedirect(orderNum) {
    return api.post('wx/v1/wolf/pay/wxRedirect', { order_num: orderNum })
}

//  连连支付
export function payLianLian(params) {
    return api.post('lianLianPay/pay', params)
}

//  mycard台湾支付
export function payMycard(params) {
    return api.post('mycardPay/pay', params)
}
